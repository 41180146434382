import React from 'react';
import SectionIntro from '../molecules/SectionIntro';

interface PlatformDistributeSectionMobileProps {
  lead: string;
  title: string;
  body: string;
  features: {
    title: string;
    description: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const PlatformDistributeSectionMobile: React.FC<PlatformDistributeSectionMobileProps> = ({
  lead,
  title,
  body,
  features,
}) => (
  <section className="py-16 md:py-32 lg:hidden">
    <SectionIntro lead={lead} title={title} body={body} />
    <div className="space-y-10 u-container">
      {features.map(({ title, description, image }, index) => (
        <div
          key={index}
          className="flex flex-col-reverse items-center justify-between lg:flex-row"
        >
          <div className="w-full lg:w-1/2 xl:w-5/12 lg:pr-16 xl:pr-32">
            <h3 className="mb-1 md:mb-5 u-h3">{title}</h3>
            <p className="pr-6 leading-relaxed text-body-copy">{description}</p>
          </div>
          <div className="w-full mb-6 lg:w-1/2 xl:w-7/12 lg:mb-0">
            <img
              src={image?.sourceUrl}
              alt={image?.altText}
              className="w-full h-auto"
            />
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default PlatformDistributeSectionMobile;
