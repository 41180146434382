import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import SectionIntro from '../molecules/SectionIntro';
import Image from '../base/Image';

interface PlatformOptimiseSectionProps {
  lead: string;
  title: string;
  body: string;
  features: {
    title: string;
    description: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
    transparentImage?: {
      sourceUrl: string;
    }
  }[];
}

const PlatformOptimiseSection: React.FC<PlatformOptimiseSectionProps> = ({
  lead,
  title,
  body,
  features,
}) => {
  const interval = useRef<NodeJS.Timeout>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useEffect(() => {
    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % features.length);
    }, 5000);

    setActiveIndex(0);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const overrideActive = (index: number) => {
    setActiveIndex(index);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % features.length);
    }, 5000);
  };

  return (
    <section className="py-16 bg-gray-50 md:py-32">
      <SectionIntro lead={lead} title={title} body={body} />

      <div className="u-container">
        <div className="relative mb-12 md:mb-16 homepage-features-image">
          <div className="absolute inset-0">
            <img src={require('../../images/platform-optimise.jpg')} alt="" className="object-cover w-full h-full"/>
          </div>

          <Image
            data={features[activeIndex]?.transparentImage || features[activeIndex]?.image}
            className="object-contain w-full h-full"
            objectFit="contain"
          />
        </div>
        <div className="items-start justify-between space-y-6 lg:flex lg:space-x-8 lg:space-y-0">
          {features.map(({ title, description }, index) => (
            <button
              key={index}
              className={`flex-1 p-0 m-0 text-left transition focus:outline-none ${
                activeIndex !== index && 'opacity-30 hover:opacity-100'
              }`}
              onClick={() => overrideActive(index)}
            >
              <div className="mb-9 h-0.75 bg-keyline">
                <AnimatePresence>
                  {index === activeIndex && (
                    <motion.div
                      className="h-0.75 bg-primary-red"
                      variants={{
                        initial: { opacity: 1, width: 0 },
                        in: {
                          opacity: 1,
                          width: '100%',
                          transition: {
                            type: 'tween',
                            ease: 'linear',
                            duration: 5,
                          },
                        },
                        out: {
                          opacity: 0,
                          width: '100%',
                          transition: {
                            type: 'tween',
                            ease: 'linear',
                            duration: 0.3,
                          },
                        },
                      }}
                      initial="initial"
                      exit="out"
                      animate="in"
                      transition={{
                        type: 'tween',
                        ease: 'linear',
                        duration: 5,
                      }}
                    />
                  )}
                </AnimatePresence>
              </div>
              <h3 className="u-h5 mb-1.5">{title}</h3>
              <p className="text-body-copy">{description}</p>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PlatformOptimiseSection;
