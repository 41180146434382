import React from 'react';

interface SectionIntroProps {
  lead: string;
  title: string;
  body: string;
}

const SectionIntro: React.FC<SectionIntroProps> = ({ lead, title, body }) => (
  <div className="u-container">
    <div className="w-full mb-12 lg:w-1/2 md:mb-18">
      <div className="u-s1">{lead}</div>
      <h2 className="u-h2">{title}</h2>
      <p className="u-p1">{body}</p>
    </div>
  </div>
);

export default SectionIntro;
