import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import SectionIntro from '../molecules/SectionIntro';
import Image from '../base/Image';
interface PlatformCreateSectionProps {
  lead: string;
  title: string;
  body: string;
  features: {
    title: string;
    body: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
    transparentImage: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const PlatformCreateSection: React.FC<PlatformCreateSectionProps> = ({
  lead,
  title,
  body,
  features,
}) => {
  const interval = useRef<NodeJS.Timeout>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { width } = useWindowSize();
  const isMobile = width < 1023;

  useEffect(() => {
    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % features.length);
    }, 5000);

    setActiveIndex(0);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const overrideActive = (index: number) => {
    setActiveIndex(index);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActiveIndex((index) => (index + 1) % features.length);
    }, 5000);
  };

  return (
    <section className="py-16 md:py-32 ">
      <SectionIntro lead={lead} title={title} body={body} />
      <div className="relative flex flex-col-reverse u-container lg:items-center lg:flex-row lg:justify-end">
        <div className="space-y-16 lg:w-1/2 md:space-y-15 md:pr-28">
          {features.map(({ title, body, image }, index) => (
            <div key={index}>
              <Image data={image} className="w-full h-auto mb-6 lg:hidden" />

              <div
                className="flex items-start mt-6 mb-2 cursor-pointer md:mt-0 md:mb-4"
                onClick={() => overrideActive(index)}
              >
                <div className="hidden mr-6 md:mr-10 md:block">
                  <div className="relative flex items-center justify-center w-10 h-10 font-semibold leading-none">
                    {index + 1}

                    <svg
                      className="absolute top-0 left-0 z-10 -mt-1 -ml-px w-14 h-14"
                      viewBox="0 0 60 60"
                    >
                      <motion.path
                        fill="none"
                        strokeWidth="3"
                        stroke="#000"
                        strokeOpacity="0.2"
                        style={{
                          rotate: 90,
                          translateX: 2,
                          translateY: 4,
                          scaleX: -1,
                        }}
                        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                      />
                    </svg>

                    <AnimatePresence>
                      {!isMobile && index === activeIndex && (
                        <svg
                          className="absolute top-0 left-0 z-20 -mt-1 -ml-px w-14 h-14"
                          viewBox="0 0 60 60"
                        >
                          <motion.path
                            fill="none"
                            strokeWidth="3"
                            stroke="red"
                            strokeDasharray="0 1"
                            variants={{
                              initial: {
                                pathLength: 0,
                                opacity: 1,
                              },
                              in: {
                                pathLength: 1,
                                opacity: 1,
                                transition: {
                                  type: 'tween',
                                  ease: 'linear',
                                  duration: 5,
                                },
                              },
                              out: {
                                pathLength: 1,
                                opacity: 0,
                                transition: {
                                  type: 'tween',
                                  ease: 'linear',
                                  duration: 0.3,
                                },
                              },
                            }}
                            initial="initial"
                            exit="out"
                            animate={isMobile ? 'out' : 'in'}
                            style={{
                              rotate: 90,
                              translateX: 2,
                              translateY: 4,
                              scaleX: -1,
                            }}
                            transition={{
                              type: 'tween',
                              ease: 'linear',
                              duration: 5,
                            }}
                            d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                          />
                        </svg>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <div>
                  <h3
                    className={`text-2xl md:text-3xl font-semibold transition leading-snug mt-1 lg:mt-0 ${
                      isMobile || activeIndex === index
                        ? 'opacity-100'
                        : 'opacity-20'
                    }`}
                  >
                    {title}
                  </h3>
                </div>
              </div>
              <motion.div
                className={`overflow-hidden`}
                initial={{ height: 0 }}
                animate={{
                  height: isMobile || activeIndex === index ? 'auto' : '0',
                }}
                transition={{
                  type: 'tween',
                  ease: 'easeInOut',
                  duration: 0.3,
                }}
              >
                <p className="leading-relaxed md:ml-16 md:ml-20 text-body-copy md:text-base">
                  {body}
                </p>
              </motion.div>
            </div>
          ))}
        </div>
        <div className="hidden mb-10 md:mb-16 lg:w-1/2 lg:mb-0 lg:block">
          <div className="relative inline-block">
            <div
              className="absolute inset-0 left-28 -right-5"
              style={{
                background:
                  'linear-gradient(137.62deg, #1DC8BE 14.54%, #1977F2 138.22%)',
              }}
            />

            <img
              src={features[0].transparentImage.sourceUrl}
              className="w-full h-auto opacity-0"
            />

            {features.map(({ transparentImage }, index) => (
              <div key={index} className="absolute top-0 left-0 w-full h-full">
                <Image
                  data={transparentImage}
                  className={`w-full h-auto transition-all duration-500 ${
                    index === activeIndex ? 'opacity-100' : 'opacity-0'
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlatformCreateSection;
