import React from 'react';
import FadingLogoStrip from '../molecules/FadingLogoStrip';
interface TrustedBySectionProps {
  title: string;
  logos: {
    logo: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const TrustedBySection: React.FC<TrustedBySectionProps> = ({
  title,
  logos,
}) => (
  <section className="py-12 md:py-24">
    <h2
      className="mb-4 text-lg font-semibold text-center"
      dangerouslySetInnerHTML={{ __html: title }}
    />

    <div className="max-w-5xl mx-auto my-5 overflow-hidden">
      <FadingLogoStrip logos={logos.map((l) => ({ image: l.logo }))} />
    </div>
  </section>
);

export default TrustedBySection;
