import React, { useEffect, useState } from 'react';
import { useMeasure, useWindowScroll, useWindowSize } from 'react-use';
import SectionIntro from '../molecules/SectionIntro';
import Image from '../base/Image';

interface PlatformDistributeSectionProps {
  lead: string;
  title: string;
  body: string;
  features: {
    title: string;
    description: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
    video: {
      localFile: {
        publicURL: string;
      }
    }
  }[];
}

const PlatformDistributeSection: React.FC<PlatformDistributeSectionProps> = ({
  lead,
  title,
  body,
  features,
}) => {
  const { height: windowHeight } = useWindowSize();
  const [ref, { height }] = useMeasure();
  const [activeIndex, setActiveIndex] = useState(0);
  const { y } = useWindowScroll();

  useEffect(() => {
    if (!document) {
      return;
    }

    const imageRect = document
      .getElementById('image-container')
      .getBoundingClientRect();

    let index = 0;

    for (let i = 0; i < features.length; i++) {
      const rect = document.getElementById(`box-${i}`).getBoundingClientRect();

      if (
        rect.top <
        imageRect.bottom - (imageRect.bottom - imageRect.top) / 2
      ) {
        index = i;
      }
    }

    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  }, [y]);

  useEffect(()=>{
    const video = document.getElementById(`video-${activeIndex}`);
    if (!video) return;
    video.currentTime = 0;
    video.play();
  },[activeIndex]);

  return (
    <section className="hidden py-16 md:py-32 lg:block">
      <SectionIntro lead={lead} title={title} body={body} />

      <div className="relative u-container">
        <div
          className="sticky float-right w-1/2 ml-auto"
          style={{ top: (windowHeight - 64 - height) / 2 + 64 }}
        >
          <div className="relative">
            <img
              ref={ref}
              id="image-container"
              src={features[activeIndex].image.sourceUrl}
              alt={features[activeIndex].image.altText}
              className="w-full h-auto opacity-0"
            />
            {features.map(({ title, description }, index) => (
              <div key={index} className={`w-full h-auto absolute transition-all duration-500 inset-0 ${
                index === activeIndex ? 'opacity-100' : 'opacity-0'
              }`}>
              {features[index].video ? (
                <video id={`video-${index}`} src={features[index].video.localFile.publicURL} autoPlay={false} muted={true} playsInline={true} />
              ): (
                <Image
                  data={features[index].image}
                />
              )}
              </div>
            ))}
          </div>
        </div>
        <div
          className="w-1/2 lg:w-1/2 xl:w-5/12 lg:pr-16 xl:pr-32"
          style={{ height: features.length * height }}
        >
          {features.map(({ title, description }, index) => (
            <div style={{ height }} className="flex items-center" key={index}>
              <div id={`box-${index}`}>
                <h3 className="mb-1 leading-tight md:mb-5 u-h3">{title}</h3>
                <p className="pr-6 leading-relaxed text-body-copy">
                  {description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

    </section>
  );
};

export default PlatformDistributeSection;
