import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Image from '../components/base/Image';
import PlaformMastheadAnimation from '../assets/animations/spirable-platform-masthead.json';
import CaseStudySection from '../components/organisms/CaseStudySection';
import LottieAnimation from '../components/molecules/LottieAnimation';
import HomepageBenefitsSection from '../components/organisms/HomepageBenefitsSection';
import PlatformCreateSection from '../components/organisms/PlatformCreateSection';
import PlatformDistributeSection from '../components/organisms/PlatformDistributeSection';
import PlatformFeatures from '../components/organisms/PlatformFeatures';
import PlatformOptimiseSection from '../components/organisms/PlatformOptimiseSection';
import TrustedBySection from '../components/organisms/TrustedBySection';
import PlatformDistributeSectionMobile from '../components/organisms/PlatformDistributeSectionMobile';
import Seo from '../components/base/Seo';

const PlatformPage = ({ data }) => {
  const { wpPage, heroBackground } = data;

  return (
    <Layout>
      <Seo post={wpPage} />

      <section className={`relative overflow-hidden`}>
        <div className={`grid md:grid-cols-2`}>
          <div className="flex flex-col items-center justify-center py-16">
            <div className="flex flex-col xl:items-end u-container ">
              <div className="max-w-xl mr-12">
                <h1 className="u-h1">{wpPage.platform.platformhero.title}</h1>
                <p className="max-w-full mb-8 text-lg sm:w-120 md:text-base lg:text-xl text-body-copy">
                  {wpPage.platform.platformhero.body}
                </p>
                <Link
                  to="/get-started"
                  className="rounded u-btn u-btn--primary"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>

          <div className="relative flex justify-center">
            <div className="absolute inset-0">
              <Image
                data={{ localFile: heroBackground }}
                className="w-full h-full"
              />
            </div>
            <div className="relative z-10 w-full h-full max-w-2xl lg:ml-4">
              <div
                className="relative w-full"
                style={{ paddingTop: '117.11%' }}
              >
                <div className="absolute inset-0">
                  <LottieAnimation
                    data={PlaformMastheadAnimation}
                    loop={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TrustedBySection {...wpPage.platform.trustedBySection} />
      <PlatformFeatures {...wpPage.platform.platformFeatures} />
      <PlatformCreateSection {...wpPage.platform.createSection} />

      <HomepageBenefitsSection
        background="linear-gradient(137.62deg, #1DC8BE 24.85%, #1977F2 122.12%)"
        small
        swapped
        className="bg-gray-50"
        {...wpPage.platform.automateSection}
      />
      <PlatformDistributeSection {...wpPage.platform.distributeSection} />
      <PlatformDistributeSectionMobile {...wpPage.platform.distributeSection} />
      <PlatformOptimiseSection {...wpPage.platform.optimiseSection} />
      <CaseStudySection
        {...wpPage.platform.caseStudySection}
        backgroundImage="linear-gradient(137.62deg, #1DC8BE 24.85%, #1977F2 122.12%)"
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PlatformQuery($id: String!) {
    heroBackground: file(name: { eq: "platform-bg" }) {
      ...FileImageBase64
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      platform {
        platformhero {
          title
          body
          image {
            ...Image
          }
        }
        trustedBySection {
          title
          logos {
            logo {
              sourceUrl
              localFile {
                publicURL
              }
            }
          }
        }
        platformFeatures {
          lead
          title
          features {
            icon {
              ...Image
            }
            title
            body
          }
        }
        createSection {
          lead
          title
          body
          features {
            title
            body
            image {
              ...Image
            }
            transparentImage {
              ...Image
            }
          }
        }
        automateSection {
          lead
          title
          body
          benefits {
            title
            description
            image {
              ...Image
            }
            transparentImage {
              ...Image
            }
          }
        }
        distributeSection {
          lead
          title
          body
          features {
            title
            description
            image {
              ...Image
            }
            video {
              localFile {
                publicURL
              }
            }
          }
        }
        optimiseSection {
          lead
          title
          body
          features {
            title
            description
            image {
              ...Image
            }
            transparentImage {
              ...Image
            }
          }
        }
        caseStudySection {
          logo {
            ...Image
          }
          title
          body
          cta {
            to
            label
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default PlatformPage;
